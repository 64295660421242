/**
 * 时间戳转换日期时间
 * @param { String } timestamp 时间戳
 * @return { String }
 */
const timestampToTime = timestamp => {
	let date = new Date(timestamp * 1000), // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
		Y = date.getFullYear() + "-",
		M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-",
		D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ",
		h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":",
		m = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()

	// m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':',
	// s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())

	return Y + M + D + h + m
}

export { timestampToTime }
